<template>
  <div class="wrapper">
    <div class="content-wrapper">
      <div class="body-wrapper">
        <div class="section-wrapper">
          <div class="section-title">
            <div class="decorator"></div>
            {{ title }}
          </div>
          <div class="section-body">
            <div class="inputs-wrapper btn-position">
              <div class="inputs-wraper-row">
                <div class="form-item">
                  <div class="label">企业名称：</div>
                  <el-input v-model="filter.name" placeholder="请输入企业名称"></el-input>
                </div>

                <div class="form-item">
                  <div class="label">选择地区：</div>
                  <el-cascader
                    v-model="filter.area"
                    :options="areaOption"
                    :props="{
                      expandTrigger: 'hover',
                      value: 'name',
                      label: 'name',
                      checkStrictly: true,
                    }"
                    filterable
                    clearable
                    ref="pickerOptionsRefArea"
                  ></el-cascader>
                </div>

                <div class="form-item">
                  <div class="label">选择风险：</div>
                  <el-select
                    v-model="filter.risk"
                    clearable
                    placeholder="请选择风险"
                    multiple
                    ref="pickerOptionsRefRisk"
                  >
                    <el-option
                      v-for="item in riskOption"
                      :key="item"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </div>

                <div class="form-item form-startMoney">
                  <div class="label">时间：</div>
                  <el-date-picker
                    v-model="filter.riskDate"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                    :picker-options="pickerOptions"
                    ref="pickerOptionsRef"
                  >
                  </el-date-picker>
                </div>

                <div class="form-item form-startMoney">
                  <div class="label">金额(万元)：</div>
                  <el-input
                    v-model.trim="filter.startMoney"
                    placeholder="请输入开始金额"
                    clearable
                    oninput="value=value.replace(/[^0-9.]/g,'')"
                  ></el-input>
                  <span class="form-Range">至</span>
                  <el-input
                    v-model.trim="filter.endMoney"
                    placeholder="请输入结束金额"
                    clearable
                    oninput="value=value.replace(/[^0-9.]/g,'')"
                  ></el-input>
                </div>
              </div>
              <button
                class="btn btn-primary btn-look-up btn-absolute"
                data-index="100"
                @click="btnSearchClick"
              >
                查询
              </button>
            </div>
          </div>
          <div>
            <el-table
              :data="tableData"
              :default-sort="{ prop: 'date', order: 'descending' }"
              style="width: 100%"
              :row-class-name="tableRowClassName"
            >
              <!-- <el-table-column prop="id" label="编号" width="70px"></el-table-column> -->
              <el-table-column
                prop="companyName"
                label="企业名称"
                min-width="25%"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="companyArea"
                label="地区"
                min-width="12%"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="totalAmount"
                label="金额（万元）"
                min-width="12%"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="riskList"
                label="风险"
                min-width="18%"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ JSON.parse(scope.row.riskList).join(",") }}
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="description">
            <div class="title">风险说明</div>
            <div class="desc">
              <div class="text" v-for="(aRiskDesc, index) in riskDesc" :key="index">
                {{ aRiskDesc.riskName }}：{{ aRiskDesc.riskExplain }}
              </div>
            </div>
          </div>
          <div class="paginations">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-sizes="[10, 20, 30, 50]"
              :page-size="pagesize"
              layout="total,prev, pager, next, jumper,sizes"
              :total="totalElements"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCompanyRiskDetails,
  getVisualArea,
  getVisualRisk,
  getVisualRiskOptions,
  getInvoiceSection,
} from "../../api/api";

const moment = require("moment");

import { getDesensitizationFromRouter } from "@/utils/helper";

export default {
  name: "ResultHeadMiddlePage",
  props: {
    uuid: String,
    type: [String, Number],
  },
  async mounted() {
    await this.getDataRange();
  },
  async activated() {
    this.$scrollTo(document.querySelector("body"), 0);
    await this.loadAreaOptions();
    await this.loadRiskOptions();
    await this.loadRisk();

    const area = this.$route.query.area;
    this.filter.name = "";
    this.pageNum = 1;
    this.filter.area = [];
    this.filter.riskOption = [];
    this.areaOption.forEach((province) => {
      if (province.name == area) {
        this.filter.area = [province.name];
      } else {
        province.children.forEach((city) => {
          if (city.name == area) {
            this.filter.area = [province.name, city.name];
          }
        });
      }
    });
    await this.search();
  },
  computed: {
    title() {
      if (this.type == 1) {
        return "客户风险";
      } else if (this.type == 2) {
        return "供应商风险";
      }
      return "供应商风险";
    },
  },
  data() {
    return {
      filter: {
        name: "",
        area: "",
        risk: "",
        riskDate: [],
      },
      riskDesc: [],
      riskOption: [],
      areaOption: [],
      pageNum: 1,
      pagesize: 10,
      totalElements: 0,
      tableData: [],
      pickerOptions: {},
      riskTimeRange: [],
    };
  },
  methods: {
    getDataRange() {
      getInvoiceSection(this.$route.params.uuid).then((res) => {
        let times = res.data;
        [this.riskTimeRange[0], this.riskTimeRange[1]] = [times.startDate, times.endDate];
        if (!this.riskTimeRange[0]) return;
        this.pickerOptions.disabledDate = (time) => {
          return (
            time.getTime() < this.riskTimeRange[0] ||
            time.getTime() > this.riskTimeRange[1]
          );
        };
        this.filter.riskDate = [];
        [this.filter.riskDate[0], this.filter.riskDate[1]] = [
          moment(times.startDate).format("YYYY-MM-DD"),
          moment(times.endDate).format("YYYY-MM-DD"),
        ];
      });
    },

    tableRowClassName({ row, rowIndex }) {
      if (row.taxNo == this.$route.query.taxNo) {
        return "warning-row";
      }
    },
    async loadAreaOptions() {
      const res = await getVisualArea(this.$route.params.uuid, this.$route.params.type);
      const data = [...res.data];
      for (const i0 in data) {
        for (const i1 in data[i0].children) {
          delete data[i0].children[i1].children;
        }
      }
      this.areaOption = data;
    },
    async loadRiskOptions() {
      const res = await getVisualRiskOptions(
        this.$route.params.uuid,
        this.$route.params.type
      );
      this.riskOption = res.data;
    },
    async loadRisk() {
      const res = await getVisualRisk();

      this.riskDesc = res.data;
    },
    async btnSearchClick() {
      if (Number(this.filter.startMoney) > Number(this.filter.endMoney)) {
        this.$message({
          message: "开始金额不能大于结束金额",
          type: "warning",
        });
        return;
      }
      if (
        (!this.filter.startMoney && !this.filter.endMoney) ||
        (this.filter.startMoney && this.filter.endMoney)
      ) {
        this.pageNum = 1;
        this.search();
      } else {
        this.$message({
          message: "金额区间不能为空",
          type: "warning",
        });
      }
    },
    async search() {
      //如果没有选择地区 那么使用传入的地区进行搜索 如果 都没有 则地区为空
      let companyArea = "";
      if (this.filter.area.length > 0) {
        companyArea = [...this.filter.area].pop();
      }

      const area = this.areaOption.find((v) => v.name == this.filter.area);

      const areaOptionLevelType = area ? area.type : "";

      let params = {
        companyArea,
        companyName: this.filter.name,
        pageNum: this.pageNum,
        pageSize: this.pagesize,
        programId: this.uuid,
        riskList: this.filter.risk,
        companyType: this.type,
        type: areaOptionLevelType,
        desensitized: getDesensitizationFromRouter(),
        startMoney: this.filter.startMoney,
        endMoney: this.filter.endMoney,
        startDate: this.filter.riskDate ? this.filter.riskDate[0] : "",
        endDate: this.filter.riskDate ? this.filter.riskDate[1] : "",
      };
      const data = await getCompanyRiskDetails(params);
      if (data.code == 200) {
        this.tableData = data.list;
        this.totalElements = data.pageTotal;
      }
    },
    handleSizeChange(val) {
      this.pagesize = val;
      this.search();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.search();
    },
  },
  watch: {
    $route(to, from) {
      this.$refs.pickerOptionsRef.handleClose();
      this.$refs.pickerOptionsRefRisk.handleClose();
      this.$refs.pickerOptionsRefArea.dropDownVisible = false;
    },
  },
};
</script>

<style lang="stylus" scoped>
.wrapper
  .pc-wrapper
    background #fff;

  .content-wrapper
    width 1280px;
    margin 0 auto;

    .head-wrapper
      display flex
      align-items center;
      justify-content center;
      margin-top 80px
      position relative;

    .body-wrapper

      border 1px dashed rgba(32, 125, 251, 0.4);
      margin-top 59px

      .section-wrapper
        padding 36px 77px;
        border-top 1px dashed rgba(32, 125, 251, 0.4);
        border-bottom 1px dashed rgba(32, 125, 251, 0.4);
        margin-bottom 24px

        .section-head
          display flex
          justify-content space-between
          align-items center;

        .section-title
          font-weight: 500;
          color: rgba(0, 0, 0, 0.8);
          line-height: 47px;
          display flex
          font-size: 32px;
          align-items center

          .decorator
            background rgba(32, 125, 251, 1)
            width 11px
            height 25px
            margin-right 17px

        .section-body
          font-size 16px
          color: rgba(0, 0, 0, 0.8);
          line-height: 24px;
          margin-top 50px

          .inputs-wrapper {
            .inputs-wraper-row {
              display: flex;
              justify-content: flex-start;
              flex-wrap: wrap;

              .form-item {
                margin-right: 40px;
                margin-bottom: 16px;
                display: flex;
                align-items: center;

                .label {
                  white-space: nowrap;
                  width: 90px;
                  text-align: right;
                }

                >>> .el-input {
                  width: 240px;
                }

                // flex-basis: 30%;
                display: flex;
              }

              .form-startMoney {
                >>> .el-input {
                  width: 180px;
                }

                .form-Range {
                  margin: 0 12px;
                }
                >>>.el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
                  width: 240px;
                }
              }
            }
          }

        .description
          box-shadow: 1px 1px 5px #D9D9D9;
          padding 24px 40px
          margin-top 50px

          .title
            font-size: 20px;
            font-weight: 400;
            color: #061178;
            line-height: 30px;

          font-size: 16px;
          color: rgba(0, 0, 0, 0.7);
          line-height: 30px;

        .paginations {
          display flex;
          justify-content center;
          margin-top 50px
          margin-bottom: 30px;
        }

  >>> .warning-row {
    background: oldlace;
  }

  >>> .success-row {
    background: #f0f9eb;
  }

  .btn-position {
    position: relative;
  }

  .btn-absolute {
    position: absolute;
    bottom: 18px;
    right: 46px;
  }
</style>
